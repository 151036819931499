import { render, staticRenderFns } from "./Store.vue?vue&type=template&id=0191821c&"
import script from "./Store.vue?vue&type=script&lang=js&"
export * from "./Store.vue?vue&type=script&lang=js&"
import style0 from "./Store.vue?vue&type=style&index=0&id=0191821c&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QTable,QTh,QIcon,QTooltip,QCheckbox,QTd,QInput,QBtn,QMenu,QItem,QItemSection,QPagination,QDialog,QCard,QCardSection,QCardActions,QInnerLoading,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QTable,QTh,QIcon,QTooltip,QCheckbox,QTd,QInput,QBtn,QMenu,QItem,QItemSection,QPagination,QDialog,QCard,QCardSection,QCardActions,QInnerLoading})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
